.wrap {
}

.c1 {
  position: relative;
}

.c1bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: calc(100vh - 100px);
  background: url("./imgs//bg1.png") center center no-repeat;
}

.c1bg {
  background-size: cover;
}

.c1c {
  position: relative;
  height: calc(100vh - 100px);
  color: #fff;
  font-size: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
}

.c1c h2 {
  max-width: 1000px;
  line-height: 100px;
  color: #fff;
}

.c2,
.c3,
.c4 {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
}

.c2 h3 {
  color: #333;
  text-align: center;
}

.c2 h5 {
  text-align: center;
  color: #999;
  margin: 20px 0 30px 0;
}

.c2 p {
  text-align: justify;
  font-size: 14px;
  line-height: 18px;
  max-width: 80%;
  margin: 0 auto;
}

.c3ItemRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.c3Item {
  width: 22%;
  height: 280px;
  margin-bottom: 80px;
  margin-top: 30px;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-weight: 500;
}

.c3Item img {
  width: 120px;
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
}

.c4ItemRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 60px;
  align-items: stretch;
}

.c4Item {
  width: 31%;
  box-sizing: border-box;
  padding: 24px;
  margin-bottom: 80px;
  margin-top: 30px;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 3px;
  transition: all linear 500ms;
}

.c4Item:hover {
  background: #27af2a;
}

.c4Item img {
  width: 100%;
  display: block;
  margin-bottom: 30px;
}

.c4ontent li {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 12px;
  list-style-type: disc;
  margin-left: 12px;
  color: #666;
  transition: all linear 200ms;
}

.c4Item:hover .c4ontent li {
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .c1bg {
    height: 50vh;
  }
  .c1c {
    height: 50vh;
  }
  .c1c h2 {
    font-size: 30px;
    line-height: 36px;
  }
  .c2 {
    width: 100%;
    flex-direction: column;
  }
  .c21,
  .c22,
  .c23 {
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .c3 {
    flex-direction: column;
  }
  .c3ItemRow {
    flex-direction: column;
  }
  .c3Item {
    width: 100%;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #eee;
  }

  .c4ItemRow {
    flex-direction: column;
  }
  .c4Item {
    width: 100%;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
}
