.wrap {}

.c1 {
  position: relative;
}

.c1bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: calc(100vh - 100px);
  background: url("./imgs//bg1.png") center center no-repeat;
}

.c1bg {
  background-size: cover;
}

.c1c {
  position: relative;
  height: calc(100vh - 100px);
  color: #fff;
  font-size: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
}

.c1c h2 {
  max-width: 1000px;
  line-height: 100px;
  color: #fff;
}

.c2,
.c3,
.c4 {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
}

.c3 {
  margin-bottom: 300px;
}

.c2 h3 {
  color: #333;
  text-align: center;
}

.c2 h5 {
  text-align: center;
  color: #999;
  margin: 20px 0 30px 0;
}

.c2 p {
  text-align: justify;
  font-size: 14px;
  line-height: 18px;
  max-width: 80%;
  margin: 0 auto;
}

.c3l {
  width: 500px;
  background: url("./imgs/icon1.png") center center no-repeat;
  background-size: cover;
  color: #fff;
  padding: 16px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 400px;
}

.c3l p {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 22px;
}

.c3 {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.c3l a {
  color: #27af2a;
}

.c3r {
  flex-grow: 1;
  padding: 40px;
  border: 1px solid #ddd;

  padding-top: 30px;
  padding-bottom: 30px;
}

.c3row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}

.c3rItem {
  width: 45%;
}

.c3label {
  font-size: 16px;
  margin-bottom: 12px;
  color: #999;
  text-transform: uppercase;
}

.c3Value input {
  width: 100%;
  min-height: 30px;
  line-height: 30px;
  font-size: 18px;
  color: #333;
  padding: 3px 5px;
  border: 1px solid #ddd !important;
  border-radius: 5px;
  background: #edf1f4;
  outline: none;
}

.c3Value input:focus {
  background: #fff;
  border: 1px solid #ddd !important;
}

.c3rowFull textarea {
  width: 100%;
  min-height: 120px;
  line-height: 30px;
  font-size: 18px;
  color: #333;
  padding: 3px 5px;
  border: 1px solid #ddd !important;
  border-radius: 5px;
  background: #edf1f4;
  outline: none;
}

.c3rowButton {
  margin-top: 40px;
}

.c3rowButton a {
  display: inline-block;
  width: 300px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  color: #fff;
  background: #27af2a;
  border: none;
  cursor: pointer;
  opacity: 0.9;
  text-transform: uppercase;
  text-decoration: none !important;
}

.c3rowButton .disable {
  opacity: 0.5;
  cursor: not-allowed;
}

.c3rowButton a:hover {
  opacity: 1;
}

@media only screen and (max-width: 600px) {
  .c1bg {
    height: 50vh;
  }

  .c1c {
    height: 50vh;
  }

  .c1c h2 {
    font-size: 30px;
    line-height: 36px;
  }

  .c2 {
    width: 100%;
    flex-direction: column;
  }

  .c21,
  .c22,
  .c23 {
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .c3 {
    flex-direction: column;
    margin-bottom: 80px;
  }

  .c3ItemRow {
    flex-direction: column;
  }

  .c3l,
  .c3r {
    width: 100%;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }

  .c4ItemRow {
    flex-direction: column;
  }

  .c4Item {
    width: 100%;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }

  .c6Item {
    flex-direction: column;
  }

  .c6Img {
    margin-right: 0;
  }

  .c3r {
    padding: 10px;
    width: auto;
    border: none;
    box-sizing: border-box;
  }

  .c3rowFull textarea {
    width: 100%;
    box-sizing: border-box;
  }
}