.c1 {
  position: relative;
}

.c1bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: calc(100vh - 100px);
  background: url("./imgs//bg2.png") center center no-repeat;
}

.c1bg {
  background-size: cover;
}

.c1c {
  position: relative;
  height: calc(100vh - 100px);
  color: #fff;
  font-size: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
}

.c1c h2 {
  max-width: 1000px;
  line-height: 100px;
  color: #fff;
}

.c2 {
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  align-items: center;
}

.c21,
.c22,
.c23 {
  position: relative;
  width: 33%;
  padding: 40px 32px;
  box-sizing: border-box;
  height: 250px;
  color: #fff;
  font-size: 24px;
  line-height: 30px;
}

.c21 {
  background: url("./imgs/sbg3.png") center center;
  background-size: cover;
}

.c22 {
  background: url("./imgs/sbg1.png") center center;
  background-size: cover;
}

.c23 {
  background: url("./imgs/sbg2.png") center center;
  background-size: cover;
}

.map {
  width: 600px;
  height: 315px;
  background: url("./imgs/map.png") center center;
  background-size: cover;
  flex-shrink: 0;
  position: relative;
}

.c3 {
  padding-top: 60px;
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
}

.c3info {
  flex-grow: 1;
}

.c3info h3 {
  font-size: 32px;
  line-height: 36px;
  font-weight: 500;
  color: #27af2a;
  margin-bottom: 20px;
}

.c3info li {
  list-style: disc;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
  line-height: 20px;
  list-style-position: inside;
}

.map img {
  width: 40px;
  position: absolute;
}

.c3p1 {
  right: 280px;
  top: 18px;
}

.c3p2 {
  right: 106px;
  top: 160px;
}

.c3p3 {
  right: 80px;
  top: 170px;
}

.c3p4 {
  right: 110px;
  top: 140px;
}

.c4 {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
}

.c4 h2 {
  font-size: 32px;
  line-height: 36px;
  font-weight: 500;
  color: #27af2a;
  margin-bottom: 20px;
}

.c5 img {
  width: 1200px;
  display: block;
  margin: 30px auto;
}

.c4nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
}

.c4navItem {
  width: 33%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.c4navItem img {
  width: 120px;
}

.c4ItemInfo {
  border-left: 1px solid #eee;
  height: 140px;
  padding-left: 30px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding-right: 30px;
}

.c4ItemInfoTop {
  color: #27af2a;
  font-weight: bold;
  font-size: 42px;
  padding-bottom: 10px;
  border-bottom: 1px solid #27af2a;
  margin-bottom: 20px;
}

.c4ItemInfoBottom {
  color: #999;
  font-weight: 500;
  font-size: 24px;
}

.c4SubTitle {
  color: #999;
  font-size: 16px;
  max-width: 660px;
  line-height: 20px;
  font-weight: 500;
}

.mapMobile {
  display: none;
}

.c1c h2 p {
  opacity: 0;
  width: 0;
  height: 100px;
  overflow: hidden;
}

.c1c h2 p.play {
  width: 0px;
  white-space: nowrap;
  border-right: 2px solid transparent;
  animation: typing1 1.5s steps(15, end), blink-caret 0.75s step-end infinite;
  animation-fill-mode: forwards;
  opacity: 1;
}

.c1c h2 p.playDone {
  color: #fff;
  animation: none;
  width: auto;
}

.c1c h2 p.play2 {
  white-space: nowrap;
  border-right: 2px solid transparent;
  animation: typing2 1s steps(10, end), blink-caret 0.75s step-end infinite;
  animation-delay: 500ms;
  animation-fill-mode: forwards;
  opacity: 1;
}

.c1c h2 p.play2Done {
  width: auto;
  animation: none;
}

@keyframes typing1 {
  from {
    width: 0;
    color: #fff;
  }

  to {
    width: 900px;
  }
}

@keyframes typing2 {
  from {
    width: 0;
    color: #fff;
  }

  to {
    width: 700px;
  }
}

/* 光标闪啊闪 */
@keyframes blink-caret {

  from,
  to {
    box-shadow: 2px 0 0 0 transparent;
  }

  50% {
    box-shadow: 2px 0 0 0;
  }
}

@media only screen and (max-width: 600px) {
  .c1bg {
    height: 50vh;
  }

  .c1c {
    height: 50vh;
  }

  .c1c h2 {
    font-size: 30px;
    line-height: 36px;
  }

  .c2 {
    width: 100%;
    flex-direction: column;
  }

  .c21,
  .c22,
  .c23 {
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .c3 {
    flex-direction: column;
  }

  .map {
    display: none;
  }

  .mapMobile {
    display: block;
    position: relative;
  }

  .mapMobile img:first-child {
    width: 100%;
  }

  .c4 {
    margin-top: 60px;
  }

  .c4SubTitle {
    font-weight: normal;
  }

  .c4nav {
    flex-direction: column;
  }

  .c4navItem {
    width: 100%;
  }

  .c5 img {
    width: 100%;
  }

  .c1c h2 p {
    opacity: 0;
    width: 0;
    height: 40px;
    overflow: hidden;
  }
}