.wrap {
}

.c1 {
  position: relative;
}

.c1bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: calc(100vh - 100px);
  background: url("./imgs//bg1.png") center center no-repeat;
}

.c1bg {
  background-size: cover;
}

.c1c {
  position: relative;
  height: calc(100vh - 100px);
  color: #fff;
  font-size: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
}

.c1c h2 {
  max-width: 1000px;
  line-height: 100px;
  color: #fff;
}

.c2,
.c3 {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
}

.c2 h3 {
  color: #333;
}

.c2Title p {
  font-size: 20px;
  font-weight: 500;
}
.c2Title {
  margin-bottom: 40px;
}
.c2Item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}
.c2Left {
  width: 48%;
}

.c2Right {
  width: 48%;
}

.c2 img {
  width: 100%;
}

.c2Left p,
.c2Right p {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
}

.c3 h3 {
  color: #333;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 30px;
}

.c3Item {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

.c3ItemLeft {
  width: 50%;
}

.c3ItemRight {
  width: 50%;
}

.c3Info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 100px;
}

.c3InfoLeft {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-right: 100px;
}

.c3InfoTitle,
.c3InfoContent {
  min-width: 150px;
}
.c3InfoLeft .c3InfoContent {
  text-align: left;
}

.c3 img {
  width: 100%;
  display: block;
}

.c3InfoTitle {
  color: #27af2a;
  font-size: 40px;
  margin-bottom: 20px;
}

.c3InfoContent {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}

@media only screen and (max-width: 600px) {
  .c1bg {
    height: 50vh;
  }
  .c1c {
    height: 50vh;
  }
  .c1c h2 {
    font-size: 30px;
    line-height: 36px;
  }
  .c2Item {
    width: 100%;
    flex-direction: column;
  }
  .c2Left {
    width: 100%;
    margin-top: 30px;
  }

  .c2Right {
    width: 100%;
    margin-top: 30px;
  }
  .c3Info {
    padding: 5px 10px;
  }
  .c3InfoTitle {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .c3InfoContent {
    font-size: 14px;
    line-height: 18px;
  }
  .c3InfoLeft {
    padding: 5px 10px;
  }
}
