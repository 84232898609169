.wrap {
  background: #fff;
}

.header {
}
.headerInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #333;
  font-size: 14px;
  margin: 0 auto;
  max-width: 1400px;
  width: 94%;
}

.header img {
  width: 204px;
}

.headerNavWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100px;
  flex-grow: 1;
  font-weight: 500;
}

.contactWrap {
  background: #59c05b;
  height: 100%;
  display: flex;
  width: 180px;
  color: #fff;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.active {
  color: #27af2a;
}

.contactWrap p {
  color: #fff;
}

.headerNavWrap img {
  width: 38px;
  margin-bottom: 6px;
}

.headerNavWrap span {
  display: inline-block;
  text-align: center;
  margin: 0 30px;
  cursor: pointer;
  transition: all linear 200ms;
  padding-top: 40px;
  font-size: 14px;
}

.headerNavWrap span:hover {
  color: #27af2a;
}

.c6 {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.c6l {
  width: 60%;
}

.c6r {
  width: 40%;
}

.c6l h3 {
  font-size: 32px;
  line-height: 36px;
  font-weight: 500;
  color: #27af2a;
  margin-bottom: 30px;
}

.c6r img {
  width: 200px;
  margin-bottom: 20px;
}

.c6r a {
  color: #27af2a;
}

.c6lrow {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.c6lrowItem {
  font-size: 14px;
  width: 25%;
}

.c6lItemLabel {
  font-weight: bold;
}

.c6lItemValue {
  color: #666;
}

.c6rContent {
  font-size: 14px;
}

.c6rContent p {
  margin-bottom: 16px;
}

.footer {
  line-height: 50px;
  padding: 1px;
  font-size: 12px;
  font-weight: 500;
  color: #333;
  border-top: 1px solid #000;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  margin-top: 100px;
}

.mapMobile {
  display: none;
}

.mNav {
  display: none;
}

@media only screen and (max-width: 600px) {
  .mNav {
    position: fixed;
    display: block;
  }
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    background: #fff;
  }
  .header img {
    width: 100px;
  }
  .headerNavWrap {
    display: none;
  }

  .headerInner {
    padding: 5px;
    justify-content: space-between;
  }
  .navMobile {
    display: block;
    width: 22px;
    height: 22px;
    border: 1px solid #ddd;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2px;
  }

  .navMobile span {
    display: block;
    width: 100%;
    background: #bbb;
    height: 2px;
    margin: 2px 0;
  }

  .navMobileWrap {
    display: flex;
    flex-direction: column;
    position: fixed;
    align-items: center;
    justify-content: center;
    top: 56px;
    right: 0;
    height: calc(100vh - 56px);
    width: 80%;
    z-index: 999;
    background: rgba(255, 255, 255, 0.9);
    transform: translateX(100%);
    transition: all linear 200ms;
  }
  .navMobileWrapShow {
    transform: translateX(0);
  }

  .navMobileWrap span {
    display: block;
    color: #333;
    line-height: 50px;
    padding: 0 20px;
    font-weight: 500;
    border-bottom: 1px solid #ddd;
    width: 90%;
    margin: 0 auto;
  }
  .c6 {
    flex-direction: column;
  }
  .c6l {
    width: 100%;
  }
  .c6r {
    width: 100%;
    margin-top: 30px;
  }
  .c6lrowItem {
    font-size: 12px;
  }
  .c6r img {
    display: block;
    margin: 20px auto;
  }
}
