.wrap {
}

.c1 {
  position: relative;
}

.c1bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: calc(100vh - 100px);
  background: url("./imgs//bg1.png") center center no-repeat;
}

.c1bg {
  background-size: cover;
}

.c1c {
  position: relative;
  height: calc(100vh - 100px);
  color: #fff;
  font-size: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
}

.c1c h2 {
  max-width: 1000px;
  line-height: 100px;
  color: #fff;
}

.c2,
.c3,
.c4 {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
}

.c3 {
  margin-bottom: 300px;
}

.c2 h3 {
  color: #333;
  text-align: center;
}

.c2 h5 {
  text-align: center;
  color: #999;
  margin: 20px 0 30px 0;
}

.c2 p {
  text-align: justify;
  font-size: 14px;
  line-height: 18px;
  max-width: 80%;
  margin: 0 auto;
}

.c3ItemRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 60px;
}

.c3Item {
  width: 48%;
}

.c3Item img {
  width: 100%;
}

.c3ItemImg {
  margin-bottom: 20px;
  display: none;
}

.c3Name {
  font-size: 28px;
  color: #000;
  font-weight: 500;
  line-height: 40px;
}

.c3Position {
  font-size: 20px;
  line-height: 36px;
}

.c3Info {
  margin-top: 40px;
}
.c3Info p {
  color: #666;
  margin-bottom: 16px;
  line-height: 20px;
  font-size: 16px;
}

.c3Label {
  color: #333;
  font-weight: 500;
  margin-right: 8px;
}

@media only screen and (max-width: 600px) {
  .c1bg {
    height: 50vh;
  }
  .c1c {
    height: 50vh;
  }
  .c1c h2 {
    font-size: 30px;
    line-height: 36px;
  }
  .c2 {
    width: 100%;
    flex-direction: column;
  }
  .c21,
  .c22,
  .c23 {
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .c3ItemRow {
    flex-direction: column;
  }
  .c3Item {
    width: 100%;
    margin-bottom: 30px;
    border-bottom: 1px solid #eee;
    padding-bottom: 30px;
  }
}
